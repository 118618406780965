import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchUsers,
    grantAdminRights,
    grantAuthorRights,
    grantTier,
    revokeAdminRights,
    revokeAuthorRights,
    revokeTier
} from "../../data/redux/actions/users";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import EmailVerified from '@material-ui/icons/VerifiedUser';
import Icon from "@material-ui/core/Icon";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useFirebase} from "react-redux-firebase";


const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    avatar: {
        margin: 10,
    },

}));

const UsersContainer = () => {
    const accessToken = useSelector(state => state.firebase.auth.stsTokenManager.accessToken);
    const auth = useSelector(state => state.firebase.auth);
    const users = useSelector(state => state.users);
    let dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileMenuOpen = Boolean(anchorEl);
    const firebase = useFirebase();


    useEffect(() => {
        dispatch(fetchUsers(accessToken));
    }, [dispatch, accessToken]);

    function hasCustomClaims(user) {
        return user.customClaims === undefined;
    }

    function isAdmin(user) {
        if (hasCustomClaims(user)) return false;
        if (user.customClaims.admin === undefined) return false;

        return user.customClaims.admin;
    }

    function getTier(user) {
        if (hasCustomClaims(user)) return "free";
        if (user.customClaims.tier === undefined) return "free";
        return user.customClaims.tier;
    }

    function isAuthor(user) {
        if (hasCustomClaims(user)) return false;
        if (user.customClaims.author === undefined) return false;

        return user.customClaims.author;
    }

    function onGrantAdminRights(user) {
        if (isAdmin(user)) {
            dispatch(revokeAdminRights(user.uid, accessToken));
        } else {
            dispatch(grantAdminRights(user.uid, accessToken));
        }
    }

    function onGrantAuthorRights(user) {
        if (isAuthor(user)) {
            dispatch(revokeAuthorRights(user.uid, accessToken));
        } else {
            dispatch(grantAuthorRights(user.uid, accessToken));
        }
    }

    function onGrantTier(user) {
        if (getTier(user) === 'free') {
            dispatch(grantTier(user.uid, accessToken));
        } else {
            dispatch(revokeTier(user.uid, accessToken));
        }
    }

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    async function logout() {
        await firebase.logout();
        //firebase.logout().then(() => console.log("Logout successfully")).catch(() => console.log("Logout error"));
    }

    if (users.error) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                m={10}
            >
                <Typography variant={"h3"}><em>{users.errorMessage}</em></Typography>
            </Box>
        );
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Logs4Dogs User Admin
                    </Typography>
                    <Typography variant="body1" noWrap>
                        {auth.displayName}
                    </Typography>
                    <div>
                        <Avatar alt="Remy Sharp" onClick={handleMenu}
                                src={auth.photoURL} className={classes.avatar}/>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={profileMenuOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            {users.isLoading && <LinearProgress color="secondary"/>}
            <Box className={classes.root} m={4}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Last login</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell align="center">Provider</TableCell>
                            <TableCell align="center">Admin</TableCell>
                            <TableCell align="center">Author</TableCell>
                            <TableCell>Tier</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.users.map(user => (
                            <TableRow key={user.uid}>
                                <TableCell component="th" scope="row">
                                    {user.displayName}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex"
                                         alignItems="center">
                                        {user.emailVerified && <EmailVerified color="secondary"/>}
                                        <Box ml={2}>{user.email}</Box>
                                    </Box>
                                </TableCell>
                                <TableCell>{user.metadata.lastSignInTime}</TableCell>
                                <TableCell>{user.metadata.creationTime}</TableCell>
                                <TableCell align="center">
                                    {user.providerData[0].providerId === "google.com" ?
                                        <Icon className="fa fa-google" color="error"/>
                                        : <Icon className="fa fa-envelope" color="action"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <Switch checked={isAdmin(user)} onChange={() => onGrantAdminRights(user)}
                                            value="hidden"
                                            color="secondary"/>
                                </TableCell>
                                <TableCell align="center">
                                    <Switch checked={isAuthor(user)} onChange={() => onGrantAuthorRights(user)}
                                            value="hidden" color="secondary"/>
                                </TableCell>
                                <TableCell>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender2" value={getTier(user)}
                                                    onChange={() => onGrantTier(user)}>
                                            <FormControlLabel
                                                value="free"
                                                control={<Radio color="secondary"/>}
                                                label="Free"
                                            />
                                            <FormControlLabel
                                                value="pro"
                                                control={<Radio color="secondary"/>}
                                                label="Professional"
                                            />

                                        </RadioGroup>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Box>
        </Box>

    );
};

export default UsersContainer;