export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_FULFILLED = "FETCH_USERS_FULFILLED";
export const FETCH_USERS_REJECTED = "FETCH_USERS_REJECTED";

export const GRANT_ADMIN_RIGHTS = "GRANT_ADMIN_RIGHTS";
export const GRANT_ADMIN_RIGHTS_FULFILLED = "GRANT_ADMIN_RIGHTS_FULFILLED";
export const GRANT_ADMIN_RIGHTS_REJECTED = "GRANT_ADMIN_RIGHTS_REJECTED";

export const REVOKE_ADMIN_RIGHTS = "REVOKE_ADMIN_RIGHTS";
export const REVOKE_ADMIN_RIGHTS_FULFILLED = "REVOKE_ADMIN_RIGHTS_FULFILLED";
export const REVOKE_ADMIN_RIGHTS_REJECTED = "REVOKE_ADMIN_RIGHTS_REJECTED";

export const GRANT_AUTHOR_RIGHTS = "GRANT_AUTHOR_RIGHTS";
export const GRANT_AUTHOR_RIGHTS_FULFILLED = "GRANT_AUTHOR_RIGHTS_FULFILLED";
export const GRANT_AUTHOR_RIGHTS_REJECTED = "GRANT_AUTHOR_RIGHTS_REJECTED";

export const REVOKE_AUTHOR_RIGHTS = "REVOKE_AUTHOR_RIGHTS";
export const REVOKE_AUTHOR_RIGHTS_FULFILLED = "REVOKE_AUTHOR_RIGHTS_FULFILLED";
export const REVOKE_AUTHOR_RIGHTS_REJECTED = "REVOKE_AUTHOR_RIGHTS_REJECTED";


export const GRANT_TIER = "GRANT_TIER";
export const GRANT_TIER_FULFILLED = "GRANT_TIER_FULFILLED";
export const GRANT_TIER_REJECTED = "GRANT_TIER_REJECTED";

export const REVOKE_TIER = "REVOKE_TIER";
export const REVOKE_TIER_FULFILLED = "REVOKE_TIER_FULFILLED";
export const REVOKE_TIER_REJECTED = "REVOKE_TIER_REJECTED";