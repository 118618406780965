import React from 'react';
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import GoogleButton from "react-google-button";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withFirebase} from "react-redux-firebase";

const LoginPage = ({firebase}) => {

    function loginWithGoogle() {
        return firebase.login({provider: 'google', type: 'popup'})
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
            <Box m={4} display="flex" alignItems="center" flexDirection="column">
                <Typography variant="h3" color="secondary">Logs4Dogs</Typography>
                <Typography variant="h6" color="secondary">User Admin</Typography>
            </Box>
            <GoogleButton onClick={loginWithGoogle}/>
        </Box>
    );
};

LoginPage.propTypes = {
    firebase: PropTypes.shape({
        login: PropTypes.func.isRequired
    }),
};

export default compose(
    withFirebase,
)(LoginPage);