import {
    FETCH_USERS,
    FETCH_USERS_FULFILLED,
    FETCH_USERS_REJECTED,
    GRANT_ADMIN_RIGHTS,
    GRANT_ADMIN_RIGHTS_FULFILLED,
    GRANT_ADMIN_RIGHTS_REJECTED,
    GRANT_AUTHOR_RIGHTS,
    GRANT_AUTHOR_RIGHTS_FULFILLED,
    GRANT_AUTHOR_RIGHTS_REJECTED,
    GRANT_TIER,
    GRANT_TIER_FULFILLED,
    GRANT_TIER_REJECTED,
    REVOKE_ADMIN_RIGHTS,
    REVOKE_ADMIN_RIGHTS_FULFILLED,
    REVOKE_ADMIN_RIGHTS_REJECTED,
    REVOKE_AUTHOR_RIGHTS,
    REVOKE_AUTHOR_RIGHTS_FULFILLED,
    REVOKE_AUTHOR_RIGHTS_REJECTED,
    REVOKE_TIER,
    REVOKE_TIER_FULFILLED,
    REVOKE_TIER_REJECTED
} from "./actions";

function getAuthorizationHeader(token) {
    return new Headers({
        'Authorization': 'Bearer ' + token,
    });
}

export function fetchUsers(token) {
    return function (dispatch) {
        dispatch({type: FETCH_USERS});

        fetch('https://us-central1-logs4dogs.cloudfunctions.net/api/users',
            {
                headers: getAuthorizationHeader(token),
            })
            .then(response => Promise.all([response, response.json()]))
            .then(([response, users]) => {
                if (response.status === 200) {
                    dispatch({type: FETCH_USERS_FULFILLED, payload: users.users})
                } else {
                    console.log("response", response);
                    dispatch({type: FETCH_USERS_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => {
                console.log("error", error);
                dispatch({type: FETCH_USERS_REJECTED, payload: error})
            });
    }
}

export function grantAdminRights(uid, token) {
    return function (dispatch) {
        dispatch({type: GRANT_ADMIN_RIGHTS});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/roles/admin`,
            {
                method: 'PUT',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                console.log("response", response);
                if (response.status === 200) {
                    dispatch({type: GRANT_ADMIN_RIGHTS_FULFILLED, payload: uid});
                } else {
                    dispatch({type: GRANT_ADMIN_RIGHTS_REJECTED, payload: response.status + ' ' + response.statusText});
                }
            })
            .catch(error => dispatch({type: GRANT_ADMIN_RIGHTS_REJECTED, payload: error}));
    }
}

export function revokeAdminRights(uid, token) {
    return function (dispatch) {
        dispatch({type: REVOKE_ADMIN_RIGHTS});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/roles/admin`,
            {
                method: 'DELETE',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: REVOKE_ADMIN_RIGHTS_FULFILLED, payload: uid});
                } else {
                    dispatch({type: REVOKE_ADMIN_RIGHTS_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => dispatch({type: REVOKE_ADMIN_RIGHTS_REJECTED, payload: error}));
    }
}

export function grantAuthorRights(uid, token) {
    return function (dispatch) {
        dispatch({type: GRANT_AUTHOR_RIGHTS});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/roles/author`,
            {
                method: 'PUT',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: GRANT_AUTHOR_RIGHTS_FULFILLED, payload: uid});
                } else {
                    dispatch({type: GRANT_AUTHOR_RIGHTS_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => dispatch({type: GRANT_AUTHOR_RIGHTS_REJECTED, payload: error}));
    }
}

export function revokeAuthorRights(uid, token) {
    return function (dispatch) {
        dispatch({type: REVOKE_AUTHOR_RIGHTS});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/roles/author`,
            {
                method: 'DELETE',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: REVOKE_AUTHOR_RIGHTS_FULFILLED, payload: uid});
                } else {
                    dispatch({type: REVOKE_AUTHOR_RIGHTS_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => dispatch({type: REVOKE_AUTHOR_RIGHTS_REJECTED, payload: error}));
    }
}

export function grantTier(uid, token) {
    return function (dispatch) {
        dispatch({type: GRANT_TIER});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/tier/pro`,
            {
                method: 'PUT',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: GRANT_TIER_FULFILLED, payload: {uid: uid, tier: "pro"}});
                } else {
                    dispatch({type: GRANT_TIER_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => dispatch({type: GRANT_TIER_REJECTED, payload: error}));
    }
}

export function revokeTier(uid, token) {
    return function (dispatch) {
        dispatch({type: REVOKE_TIER});

        fetch(`https://us-central1-logs4dogs.cloudfunctions.net/api/users/uid/${uid}/tier`,
            {
                method: 'DELETE',
                headers: getAuthorizationHeader(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: REVOKE_TIER_FULFILLED, payload: {uid: uid, tier: "free"}});
                } else {
                    dispatch({type: REVOKE_TIER_REJECTED, payload: response.statusText});
                }
            })
            .catch(error => dispatch({type: REVOKE_TIER_REJECTED, payload: error}));
    }
}

