import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {isEmpty, isLoaded, withFirebase} from 'react-redux-firebase'
import LoginPage from "./login_page";
import LoadingPage from "./loading_page";
import UsersContainer from "../users/users_container"; // optional

function LoginContainer({auth}) {
    return !isLoaded(auth) ? <LoadingPage/> : isEmpty(auth) ? <LoginPage/> : <UsersContainer/>
}

LoginContainer.propTypes = {
    auth: PropTypes.object
};

export default compose(
    withFirebase,
    connect(({firebase: {auth}}) => ({auth}))
)(LoginContainer)