import {
    FETCH_USERS,
    FETCH_USERS_FULFILLED,
    FETCH_USERS_REJECTED,
    GRANT_ADMIN_RIGHTS,
    GRANT_ADMIN_RIGHTS_FULFILLED,
    GRANT_ADMIN_RIGHTS_REJECTED,
    GRANT_AUTHOR_RIGHTS,
    GRANT_AUTHOR_RIGHTS_FULFILLED,
    GRANT_AUTHOR_RIGHTS_REJECTED, GRANT_TIER, GRANT_TIER_FULFILLED, GRANT_TIER_REJECTED,
    REVOKE_ADMIN_RIGHTS,
    REVOKE_ADMIN_RIGHTS_FULFILLED,
    REVOKE_ADMIN_RIGHTS_REJECTED,
    REVOKE_AUTHOR_RIGHTS,
    REVOKE_AUTHOR_RIGHTS_FULFILLED,
    REVOKE_AUTHOR_RIGHTS_REJECTED, REVOKE_TIER, REVOKE_TIER_FULFILLED, REVOKE_TIER_REJECTED
} from "../actions/actions";

export default function reducer(state = {
    users: [],
    isLoading: false,
    loaded: false,
    error: false,
    errorMessage: "",
}, action) {

    switch (action.type) {
        case FETCH_USERS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case FETCH_USERS_FULFILLED:
            return {...state, isLoading: false, loaded: true, users: action.payload};

        case FETCH_USERS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case GRANT_ADMIN_RIGHTS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case GRANT_ADMIN_RIGHTS_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload ? {
                    ...user,
                    customClaims: {...user.customClaims, admin: true}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };


        case GRANT_ADMIN_RIGHTS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case REVOKE_ADMIN_RIGHTS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case REVOKE_ADMIN_RIGHTS_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload ? {
                    ...user,
                    customClaims: {...user.customClaims, admin: false}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };

        case REVOKE_ADMIN_RIGHTS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case GRANT_AUTHOR_RIGHTS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case GRANT_AUTHOR_RIGHTS_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload ? {
                    ...user,
                    customClaims: {...user.customClaims, author: true}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };


        case GRANT_AUTHOR_RIGHTS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case REVOKE_AUTHOR_RIGHTS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case REVOKE_AUTHOR_RIGHTS_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload ? {
                    ...user,
                    customClaims: {...user.customClaims, author: false}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };

        case REVOKE_AUTHOR_RIGHTS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case GRANT_TIER:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case GRANT_TIER_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload.uid ? {
                    ...user,
                    customClaims: {...user.customClaims, tier: action.payload.tier}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };


        case GRANT_TIER_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case REVOKE_TIER:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case REVOKE_TIER_FULFILLED:
            return {
                ...state,
                users: state.users.map(user => user.uid === action.payload.uid ? {
                    ...user,
                    customClaims: {...user.customClaims, tier: action.payload.tier}
                } : user),

                isLoading: false,
                loaded: true,
                error: false,
                errorMessage: ""
            };

        case REVOKE_TIER_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};



        default:
            return state;

    }
}

